const Elios = {
  metadata: {
      name: "elios",
      display: "yes"
  },
  header: {
    name: "🚧 Under Construction 🚧",
    catchphrase: "Come back later to learn about this project."
  },
  sections: []
};

export default Elios;